<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        연수원 이용 신청
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="view-contents">

        <!-- content-section:신청안내 -->
        <article class="content-section">
          <header class="section-header header-divider">
            <h4 class="title">신청안내</h4>
          </header>
          <div class="section-content">
            <p class="font-body14">신청 진행 중입니다. 아래 세부 정보 입력후 [최종 신청] 하셔야 합니다. 동반가족 필수 입력 (입력된 가족 외 입소 제한)
              <br/>직원간 이용은 코로나 19 감염 예방을 위해 별도 통보시까지 제한
            </p>
          </div>
        </article>

        <!-- content-section:신청 정보 -->
        <article class="content-section">
          <header class="section-header">
            <h3 class="title">신청 정보</h3>
          </header>
          <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
            <!-- kb-form-field:비상 연락처 -->
            <div class="kb-form-field field-first">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">비상 연락처</span></label>
              </div>
              <div class="kb-form-row">
                <input type="tel" maxlength="12" class="kb-form-control" placeholder="'-' 없이 숫자만으로 비상연락처를 입력하세요.'" v-model.trim="trnctAplyIns.emerCt"/>
              </div>
            </div>
            <!-- kb-form-field:차량 번호 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">차량 번호</span></label>
              </div>
              <div class="kb-form-row">
                <input type="text" class="kb-form-control" placeholder="차량번호를 입력하세요." v-model="trnctAplyIns.carNo"/>
              </div>
            </div>
            <!-- kb-form-field:서무직원 번호 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">서무직원 번호</span></label>
              </div>
              <div class="kb-form-row">
                <input type="text" class="kb-form-control" placeholder="서무직원번호를 입력하세요." v-model="trnctAplyIns.mblTelno"/>
              </div>
            </div>
            <!-- kb-form-field:신청숙실수 -->
            <div class="kb-form-field field-first" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">신청숙실수</span></label>
              </div>
              <div class="kb-form-row">
                <input type="number" class="kb-form-control" placeholder="신청 숙실수." v-model="trnctAplyIns.useRoomCnt"/>
              </div>
            </div>

            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소인원(남자)</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.mlePeopl" type="text" class="kb-form-control" placeholder=""/>
                <div class="kb-form-side">
                  명
                </div>
              </div>
            </div>

            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'N'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소인원(여자)</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.mlePeopl" type="text" class="kb-form-control" placeholder=""/>
                <div class="kb-form-side">
                  명
                </div>
              </div>
            </div>

            <!-- kb-form-field:입소자 1 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 1</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[0].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" disabled/>
                <div class="kb-form-select left-margin" >
                  <select disabled v-model="trnctAplyIns.aplyDtl[0].aplcntRel" >
                    <option value="" disabled >관계 선택</option>
                    <option value="A1" selected>본인</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 2 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 2</span></label>
                <button class="kb-btn-side-text" @click="deleteFamily(1)"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[1].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[1].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                    <!--
                    <li class="dropdown-option-item" v-for="item in familyList" :key="item.code">
                      <a href="javascript:" class="dropdown-option-link" @click="selectAply(0,item.code)">
                        <span class="dropdown-option-text">{{ item.label }}</span>
                      </a>
                    </li>
                    -->
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 3 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 3</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[2].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[2].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 4 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 4</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[3].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select required v-model="trnctAplyIns.aplyDtl[3].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- kb-form-field:입소자 5 -->
            <div class="kb-form-field" v-if="trnctAplyIns.prsnBrcYn == 'Y'">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">입소자 5</span></label>
                <button class="kb-btn-side-text"><span class="font-body12B text-red">삭제</span></button>
              </div>
              <div class="kb-form-row">
                <input v-model="trnctAplyIns.aplyDtl[4].cpnnNm" type="text" class="kb-form-control" placeholder="이름을 입력하세요" />
                <div class="kb-form-select left-margin">
                  <select  required v-model="trnctAplyIns.aplyDtl[4].aplcntRel">
                    <option v-for="(item,index) in familyList" :key="index" :value="item.code">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- kb-form-field:이용인원 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">이용인원</span></label>
              </div>
              <div class="kb-form-row">
                <span></span>
                <div class="kb-form-side">{{ trnctAplyIns.aplyPeopl}} 명</div>
              </div>
            </div>

            <!-- kb-form-field:특이사항 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label for="" class="kb-form-label"><span class="kb-form-label-text">특이사항</span></label>
              </div>
              <div class="kb-form-row">
                <textarea  v-model="trnctAplyIns.dmndCn" class="kb-form-control" placeholder="특이사항이 있을 경우 작성해주세요."></textarea>
              </div>
            </div>
            <!-- //kb-form-field -->
          </div>
        </article>

        <!-- content-section:생활연수 입소시 유의 사항 -->
        <article class="content-section">
          <header class="section-header header-divider">
            <h4 class="title">생활연수 입소시 유의 사항</h4>
          </header>
          <div class="section-content">
            <p class="font-body14" data-bullet="1.">이용일 기준 재직중인 임직원만 신청가능하며, 입소정원은 직원 본인 포함 5인 이내 직계가족, 차량은 1대로 제한합니다.</p>
            <p class="font-body14" data-bullet="2.">안식년휴가 활용 신청자는 안식년 휴가로 평일 연수시설을 이용하는 직원(이용일 기준 휴직/퇴직직원 제외)으로 전 기간 안식년 휴가 등록을 하여야 합니다.</p>
            <p class="font-body14" data-bullet="3.">입소(16:00 ~ 24:00) 퇴소 (10:00) 시간을 준수합니다</p>
            <p class="font-body14" data-bullet="4.">수건 등 세면도구는 지원되지 않으니 개별 지참하시기 바랍니다.</p>
            <p class="font-body14" data-bullet="5.">애완동물 동반 입소 금지합니다.</p>
            <p class="font-body14" data-bullet="6.">냄새를 유발하는 고기류(생선/삼겹살 등)취사를 제한합니다.</p>
            <p class="font-body14" data-bullet="7.">취소적용기준 및 사용제한 (당일취소, 무단불참, 본인불참, 객실미정리)에 대하여 숙지하였습니다.</p>
            <p class="font-body14 text-muted" data-bullet="">※당일취소란? 입소당일 휴일 입소인 경우 직전영업일</p>
            <p class="font-body14" data-bullet="8.">본인(동반가족포함) 연수원 이용 중 코로나 의심증상 발현 또는 이용 후 코로나 확진시 연수원 담당자에게 즉시 연락하겠습니다.</p>
            <p class="font-body14" data-bullet="9.">연수원 사정에 따라 생활연수 운영이 중단 될 수 있습니다. (예: 공사, 코로나19 등)</p>
            <p class="font-body14" data-bullet="10.">생활연수 안내는 리브똑똑으로 발송되오니 앱 설치 및 웹메일 인증 확인 바랍니다.</p>
          </div>
          <div class="section-bottom bottom-divider">
            <strong class="text">본인은 위 안내사항을 준수하고 기 제출한 개인(신용)정보 수집·이용·제공 동의서(임직원용)에 의거 각 연수시설 관리업체에 개인식별정보(성명,연락처,신청정보)를 제공함을 동의합니다.</strong>
            <div class="kb-form-check check-agree pt-1">
              <input type="checkbox" class="kb-form-check-input" id="chk_agree_01" v-model="trnctAgree" />
              <label for="chk_agree_01" class="kb-form-check-label" style="margin-right: 2em;position: absolute;padding-top: 0.2em;"><span class="text">동의완료</span></label>
            </div>
          </div>
        </article>

      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="nextStep" :disabled="!nextCheck()">다음</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, onMounted, reactive, ref, watch,} from 'vue';
import {useStore} from "vuex";
import {timestampToDateFormat,isTelFormat,isHpTelFormat} from "@/assets/js/util";

import {ACT_SET_TRNCT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileApplyStep2',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);

    const reserveVenue = ref('연수원');
    const trnctAgree = ref(false);
    const usePeridOption = ref([]);
    const usePeridToggle = ref(false);
    const usePerid = ref(null);
    const stbyYn = ref('N');

    const familyList = ref([]);
    familyList.value.push({ label : "관계 선택", code : null});
    familyList.value.push({ label : "본인", code : "A1"});
    familyList.value.push({ label : "배우자", code : "A2"});
    familyList.value.push({ label : "자녀", code : "A3"});
    familyList.value.push({ label : "본인(부)", code : "A4"});
    familyList.value.push({ label : "본인(모)", code : "A5"});
    familyList.value.push({ label : "배우자(부)", code : "A6"});
    familyList.value.push({ label : "배우자(모)", code : "A7"});
    familyList.value.push({ label : "조부", code : "A8"});
    familyList.value.push({ label : "조모", code : "A9"});

    const trnctAplyIns = reactive({
      prsnBrcYn : 'Y',
      aplcntId : store.state.auth.session.lrnerId, //신청자ID
      aplcntNm : store.state.auth.session.lrnerNm, //신청자명
      aplcntDeptCd : store.state.auth.session.deptCd, //신청자 부서코드
      aplcntDeptNm : store.state.auth.session.deptNm, //신장자 부서명
      aplcntJbgd : store.state.auth.session.jbgdCd, //신청자직급
      aplyPeopl : store.state.trnct.mobileApplyInfo.aplyPeopl,  //신청인원
      mlePeopl : store.state.trnct.mobileApplyInfo.mlePeopl,   //남성인원
      femPeopl : store.state.trnct.mobileApplyInfo.femPeopl,   //여성인원
      useRoomCnt : store.state.trnct.mobileApplyInfo.useRoomCnt, //사용객실수
      dmndCn : store.state.trnct.mobileApplyInfo.dmndCn, //요청내용
      carNo : store.state.trnct.mobileApplyInfo.carNo, //차량번호
      mblTelno : store.state.trnct.mobileApplyInfo.mblTelno, //휴대전화번호 서무직원번호
      emerCt : store.state.trnct.mobileApplyInfo.emerCt, //비상전화번호
      aplyDtl : [
        {
          aplcntRel : 'A1', //신청자 관계
          cpnnNm : store.state.auth.session.lrnerNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[1].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[1].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[2].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[2].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[3].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[3].cpnnNm, //동반자 명
        },
        {
          aplcntRel : store.state.trnct.mobileApplyInfo.aplyDtl[4].aplcntRel, //신청자 관계
          cpnnNm : store.state.trnct.mobileApplyInfo.aplyDtl[4].cpnnNm, //동반자 명
        }
      ]
    })

    const toggles = reactive({
      aply1 : false,
      aply2 : false,
      aply3 : false,
      aply4 : false,
      aply5 : false,
    });


    onMounted(()=> {

    })

    watch(trnctAplyIns.aplyDtl, () => {
      trnctAplyIns.aplyPeopl = trnctAplyIns.aplyDtl.filter( (v)=> {return ( v.cpnnNm != null && v.cpnnNm != '') } ).length
    });

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const nextCheck = () => {
      return trnctAgree.value == false ? false : true;
    }

    const nextStep = () => {
      if(trnctAgree.value == false){
        showMessage('동의를 선택해주시기 바랍니다');
        return;
      }

      if(trnctAplyIns.emerCt == "" || trnctAplyIns.emerCt == null){
        showMessage('비상연락처를 입력해주세요');
        return;
      }

      if(!isTelFormat(trnctAplyIns.emerCt) && !isHpTelFormat(trnctAplyIns.emerCt) ){
         showMessage('비상연락처가 잘못되었습니다.');
        return false;          
      }            
          


      if(trnctAplyIns.prsnBrcYn == 'N'){

        if(trnctAplyIns.mlePeopl == null || trnctAplyIns.mlePeopl == ''){
          trnctAplyIns.mlePeopl = 0;
        }

        if(trnctAplyIns.femPeopl == null || trnctAplyIns.femPeopl == ''){
          trnctAplyIns.femPeopl = 0;
        }
        trnctAplyIns.aplyPeopl = parseInt(trnctAplyIns.mlePeopl) + parseInt(trnctAplyIns.femPeopl);
      }

      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, trnctAplyIns);
      router.push('/daily/apply/step3');
    }

    const deleteFamily = (index) => {
      trnctAplyIns.aplyDtl[index].aplcntRel = null;
      trnctAplyIns.aplyDtl[index].cpnnNm = null;
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      reserveVenue,
      session,
      getDateFormat,
      nextCheck,
      nextStep,
      usePeridOption,
      usePeridToggle,
      usePerid,
      stbyYn,
      trnctAplyIns,
      familyList,
      toggles,
      deleteFamily,
      trnctAgree,
      goBack
    };
  },
};
</script>
<style>
select{
  background:initial !important;
}
</style>
